<template>
   <div class="depositAdjust">
        <van-dialog v-model="showPop" :show-confirm-button="false" get-container="#app">
          <div class="title">定金调整</div>
          <div class="centerbox_dialog" v-if=!refund>
            
            <div class="flex orderInfo">  <div class="topCol">原有押金（元）</div>  <div class="topCol">{{originalDeposit.toFixed(2)}}</div> </div>
            <div class="flex orderInfo">  <div class="btmCol">补押金（元/克）</div> <div class="btmCol">{{repairPrice}}</div> </div>
            <div class="flex orderInfo">   <div class="btmCol">新增押金（元）</div>  <div class="btmCol">{{repair}}</div></div>
          </div>
          <div class="centerbox_dialog" v-else>

            <div class="flex orderInfo">  <div class="topCol">原有押金（元）</div>  <div class="topCol">{{originalDeposit.toFixed(2)}}</div> </div>
            <div class="flex orderInfo">  <div class="btmCol">可退押金（元/克）</div> <div class="btmCol">{{repairPrice}}</div> </div>
            <div class="flex orderInfo">   <div class="btmCol">可退押金（元）</div>  <div class="btmCol">{{repair}}</div></div>
          </div>
          <div class="flex popbtn">
            <div class="gocer closebtn" @click="close" >取消</div>
            <div class="gocer confirmbtn" @click="confirm">确定</div>
          </div>
        </van-dialog>
   </div>
</template>

<script>
import *as api from'@/utils/api'
export default {
    props:['showPop'],
     inject: ["reload"],
        data() {
            return {
                  // 订单数据
                sellPricingData:{},
                originalDeposit:0,
                priceVal:{},
                repairPrice:0,//补定金/g
                buyRepairPrice:0,//买料补定金
                repair:0,//需补定金
                orderid:'', //订单id
                refund: false,  //  用户退补押金可用
                }
        },
mounted() {
 const userInfo = JSON.parse(localStorage.getItem('userinfo'))
  this.getDepositRatio()

},

 methods:{
// 获取补退押金政策
   async getDepositRatio(){
     const res = await api.getDepositRatio()
     this.repairPrice = res.addDepositRatio
     this.buyRepairPrice = res.addDepositRatio
   },
// 获取可用押金
async getMoney(id){
            let res = await api.getEarnestMoney(id)
            this.originalDeposit = res.earnestMoney
            this.repairPrice = res.everyWeightRequireAddMoney
            this.repair = res.totalRequireAddMoney
},
async confirm(){
             this.$emit('update:showPop',false)
            let res =   await api.confirmRepairDeposit({
              orderId:this.orderid,
              orderType:this.type,
              earnestMoney:this.repair,
              depositeType: this.refund? 0: 1  // 0退押金， 1补押金
            })
            // 通知父组件关闭弹窗
            
            
            this.reload()
    },
    // 取消按钮点击事件
    close(){
      // 通知父组件关闭弹窗
      this.$emit('update:showPop',false)
    },
    // 打开弹窗
    openDialog(id,item,type,refund){
      this.$emit('update:showPop',true)
      this.refund = refund
      this.orderid = id
      this.type = type
      this.sellPricingData = item
      this.originalDeposit = item.earnestMoney
      if (type == 'SELL') {
        this.repair = this.repairPrice * item.weight
      }
      if (type == 'BUY') {
        this.repair = this.buyRepairPrice * item.weight
      }

      // this.getMoney(this.orderid)
    },
}
}
</script>

<style lang="scss" scoped>
 .van-dialog{
    width: 90% !important;
    padding: 0 40px 30px;
    .title{
        color: #242424;

    }
    .van-field{
        font-size: 28px;
    }
  }

  .popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }
  
}
.title{
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.orderInfo{
    padding: 4px 0;
}
.gocer{
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color:#fff;
  text-align: center;
}
.centerbox_dialog{
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
}
.closebtn{
    background-color: #D1D1D1;
}
.confirmbtn{
    background-color:#FF2D2E ;
}
.flex{
  display: flex;
  justify-content: space-between;
}
.topCol{
    color: #999999;
    line-height: 60px;

}
.btmCol{
    color: #242424;
    line-height: 60px;
}
</style>